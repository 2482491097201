import { authenticationService } from 'services/authentication';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useAsyncError } from 'hooks/useAsyncError';

export function Login() {
  const location = useLocation();
  const throwError = useAsyncError();

  useEffect(() => {
    async function loginRedirect() {
      try {
        const fullURL = location.pathname + location.search;
        console.log(fullURL);
        if (fullURL.includes("philip-morris")) {
          await authenticationService.onLoginRedirect(location.state && location.state.loginHint, "auth_method_signicat");
        } else {
          await authenticationService.onLoginRedirect(location.state && location.state.loginHint);
        }
      } catch (e) {
        throwError(e);
      }
    }

    loginRedirect();
  }, [location.state, throwError]);

  return (
    <></>
  );
}